const departments: {
    [key: string]: string[];
  } = {
    "Multiple Domain": [],
    Audit: ["Statutory Audit", "Internal Audit", "Forensic Audit", "Bank Audit"],
    Taxation: [
      "Direct Taxation",
      "Indirect Taxation",
      "International Taxation",
      "Transfer Pricing",
      "M&A Tax",
      "FEMA",
      "Insolvency and Bankruptcy Code",
    ],
    Finance: [
      "Investment Banking",
      "Valuations",
      "Financial Due Diligence",
      "E-Commerce",
    ],
  };

  export default departments;